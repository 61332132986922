import React, { ChangeEvent, useEffect, useState } from 'react';
import api from '../../services/api';

interface SectorInputProps {
  name: string;

  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const SectorInput: React.FC<SectorInputProps> = ({ name, onChange }) => {
  const [sectors, setSectors] = useState<any[]>([]);
  // const [isFocused, setIsFocused] = useState(false);

  // const onFocus = () => {
  //   setIsFocused(true);
  // };

  // const onBlur = () => {
  //   setIsFocused(false);
  // };

  useEffect(() => {
    async function loadSectors() {
      try {
        const response = await api.get('/sectors/active');
        setSectors(response.data);
      } catch (err) {
        console.error(err);
      }
    }

    loadSectors();
  }, []);

  // Objeto de estilo para o select
  const selectStyle: React.CSSProperties = {
    display: 'flex',
    width: '500px', // ajuste conforme necessário
    height: '50px',
    maxWidth: '100%',
    margin: '0',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
    backgroundColor: '#fff',
    color: '#555',
    fontSize: '14px',
    lineHeight: '1.42857143',
    transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
  };
  return (
    <div style={{ textAlign: 'center' }}>
      <select
        name={name}
        onChange={onChange}
        style={selectStyle} // Aplicar o estilo inline aqui
      >
        <option value="">SELECIONE UM SETOR</option>
        {sectors.map(sector => (
          <option key={sector.id} value={sector.id}>
            {sector.title}
          </option>
        ))}
      </select>
    </div>
  );
};
export default SectorInput;
